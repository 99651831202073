import React, { useState, useMemo, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

// Componentes
import {
  FormControl,
  InputLabel,
  TextField,
  MenuItem,
  InputAdornment,
  IconButton
} from "@mui/material";

// Icones
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import ClearIcon from "@mui/icons-material/Clear";

// Styles
import { customStyles, useStyles } from "./style";

const SelectArredondado = React.forwardRef((props, ref) => {
  const {
    id,
    name,
    value,
    onChange,
    dataSource,
    valueKey,
    valueName,
    label,
    maxHeight,
    maxWidth,
    marginBottom,
    placeholder,
    hideLabel,
    customSelect,
    allowClear,
    errors,
    className,
    style,
    disabled,
    labelClassName,
    isFuncionality,
    styleForm
  } = props;

  const mixedStyle = customSelect
    ? customStyles({
        maxHeight,
        maxWidth,
        marginBottom,
        isFuncionality,
        styleForm
      })
    : useStyles({ maxHeight, maxWidth, marginBottom, styleForm });

  const [aberto, setAberto] = useState(false);

  const selectProps = useMemo(() => {
    if (customSelect)
      return {
        IconComponent: () => (
          <ArrowDropDownCircleIcon
            className={mixedStyle.selectIcon}
            style={{ transform: `${aberto && "rotate(-180deg)"}` }}
          />
        ),
        onOpen: () => setAberto(true),
        onClose: () => setAberto(false)
      };
    return {};
  }, [customSelect, mixedStyle, aberto]);

  const [customValue, setCustomValue] = useState("");

  useEffect(() => {
    setCustomValue(value);
  }, [value]);

  const onClickClear = useCallback(() => {
    onChange({ target: { name, value: "" } });
    setCustomValue("");
  }, [onChange]);

  const showClear = useMemo(
    () =>
      allowClear && value ? (
        <InputAdornment position="start" className={mixedStyle.adornment}>
          <IconButton
            onClick={onClickClear}
            onMouseDown={onClickClear}
            size="small"
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        </InputAdornment>
      ) : null,
    [allowClear, value, mixedStyle.adornment, onClickClear]
  );

  return (
    <FormControl
      variant="outlined"
      fullWidth
      className={mixedStyle.formControl}
    >
      {!hideLabel && (
        <InputLabel
          className={`${mixedStyle.label} ${labelClassName}`}
          hidden={Boolean(value)}
          htmlFor={`${id}Label`}
          error={Boolean(errors[name]?.message)}
        >
          {placeholder}
        </InputLabel>
      )}
      <TextField
        select
        variant="outlined"
        value={customValue}
        onChange={onChange}
        classes={{ root: mixedStyle.select }}
        className={className}
        fullWidth
        id={id}
        name={name}
        inputRef={ref}
        disabled={disabled}
        SelectProps={selectProps}
        label={!value ? "" : label}
        InputProps={{ endAdornment: showClear }}
        error={Boolean(errors[name]?.message)}
        helperText={errors[name]?.message ?? ""}
        style={style}
      >
        {dataSource?.length
          ? dataSource.map((item, i) => {
              return (
                <MenuItem key={i} value={String(item[valueKey])}>
                  {item[valueName]}
                </MenuItem>
              );
            })
          : []}
      </TextField>
    </FormControl>
  );
});

SelectArredondado.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.any]),
  onChange: PropTypes.oneOfType([PropTypes.func]),
  dataSource: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  valueKey: PropTypes.string,
  valueName: PropTypes.string,
  label: PropTypes.string,
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  marginBottom: PropTypes.string,
  placeholder: PropTypes.string,
  hideLabel: PropTypes.bool,
  customSelect: PropTypes.bool,
  allowClear: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.object]),
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object]),
  disabled: PropTypes.bool,
  labelClassName: PropTypes.string,
  isFuncionality: PropTypes.bool,
  styleForm: PropTypes.bool
};

SelectArredondado.defaultProps = {
  id: "",
  value: "",
  onChange: () => {},
  dataSource: [],
  valueKey: "",
  valueName: "",
  label: "",
  maxHeight: "auto",
  maxWidth: "auto",
  marginBottom: "10px",
  placeholder: "",
  hideLabel: false,
  customSelect: false,
  allowClear: false,
  errors: {},
  className: "",
  style: {},
  disabled: false,
  labelClassName: "",
  isFuncionality: false,
  styleForm: true
};

export default SelectArredondado;
