import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    margin: "0",
    width: "100%"
  },
  desabledInput: {
    pointerEvents: "none",
    opacity: 0.5
  }
});
