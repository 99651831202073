import { makeStyles } from "@mui/styles";
import theme from "themes";

export const useStyles = makeStyles({
  titulo: {
    fontSize: "18px",
    fontWeight: 700
  },
  botao: {
    fontSize: "18px",
    lineHeight: "20px",
    width: "auto !important",
    padding: "5px 36px",
    "& svg": {
      marginRight: "5px"
    }
  },
  botaoCopiar: {
    fontSize: "14px",
    lineHeight: "16px",
    maxHeight: "30px",
    padding: "5px 36px",
    width: "auto !important"
  },
  card: {
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0px 4px 10px 0px #00000040  !important"
  },
  cardTexto: {
    fontSize: "14px",
    lineHeight: "16px",
    margin: "0"
  },
  cardCuponNome: {
    color: `${theme.color.primary} !important`,
    fontSize: "24px",
    lineHeight: "28.8px",
    fontWeight: 700
  },
  boxSelecionar: {
    color: `${theme.color.secondaryBorderColor} !important`,
    fontSize: "28px",
    margin: "200px auto 0",
    maxWidth: "500px",
    textAlign: "center"
  },
  boxNaoEncontrato: {
    color: `${theme.color.secondaryBorderColor} !important`,
    fontSize: "28px",
    margin: "100px auto 100px",
    maxWidth: "500px",
    textAlign: "center"
  }
});
