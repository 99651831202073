import React, { useEffect, useState } from "react";
import { Card, Grid, Box } from "@mui/material";
import { Loader, Botao } from "componentes";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useSelector } from "react-redux";
import QRCode from "react-qr-code";
import MemberGetMember from "./helper";

// style
import { useStyles } from "./style";

const ConvideGanhe = () => {
  const classes = useStyles();
  const global = useSelector((state) => state.usuario);
  const { clienteSelecionado } = global;
  const [carregandoCupom, setCarregandoCupom] = useState(false);
  const [clienteCupom, setClienteCupom] = useState([]);

  const obterCupom = async () => {
    if (!clienteSelecionado) {
      setClienteCupom([]);
      return;
    }

    const resposta = await MemberGetMember.obterClienteCupomIndicador(
      clienteSelecionado,
      setCarregandoCupom
    );

    if (!resposta.sucesso) {
      setCarregandoCupom(false);
      setClienteCupom([]);
      MemberGetMember.exibirErro(resposta.mensagem);
    } else if (!resposta?.data) {
      setClienteCupom([]);
    } else {
      setClienteCupom(resposta.data);
    }
  };

  const copiarCupom = (cupom) => {
    if (!cupom) return;
    const textField = document.createElement("textarea");
    textField.innerText = cupom;
    document.body.appendChild(textField);
    textField.select();
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(cupom);
    } else {
      document.execCommand("copy");
    }

    textField.remove();
  };

  const onImageDownload = (cupom) => {
    const svg = document.getElementById(`QRCode-${cupom.codigo}`);
    if (svg && cupom?.codigo) {
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.download = cupom?.codigo?.replaceAll(/[^\d]/g, "");
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
      };
      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    }
  };

  useEffect(() => {
    obterCupom();
  }, [clienteSelecionado]);

  if (!clienteSelecionado)
    return (
      <div className={classes.boxSelecionar}>
        É necessário escolher um cliente para continuar!
      </div>
    );

  return (
    <Loader loading={carregandoCupom}>
      <Card>
        <Grid container py={3} px={2}>
          {clienteCupom?.length ? (
            <>
              <Grid item xs={12} px={2}>
                <h3 className={classes.titulo}>
                  Acesse o código de indicação do cliente abaixo:
                </h3>
              </Grid>

              <Grid container item xs={12} sm={6} lg={4} my={2} px={2}>
                <Grid item xs={12} my={2}>
                  <Card className={classes.card}>
                    <Box>
                      <p className={classes.cardTexto}>Código de indicação:</p>
                      <h3 className={classes.cardCuponNome}>
                        {clienteCupom[0]?.codigo}
                      </h3>
                    </Box>
                    <Botao
                      label="Copiar"
                      className={classes.botaoCopiar}
                      onClick={() => copiarCupom(clienteCupom[0]?.codigo)}
                    />
                  </Card>
                </Grid>
                <Grid item xs={12} my={2}>
                  <QRCode
                    id={`QRCode-${clienteCupom[0]?.codigo}`}
                    value={clienteCupom[0]?.url}
                    bgColor="transparent"
                  />
                </Grid>
                <Grid item xs={12} my={2}>
                  <Botao
                    label="Gerar um arquivo"
                    icon={<FileUploadIcon />}
                    onClick={() => {
                      if (clienteCupom[0]) {
                        onImageDownload(clienteCupom[0]);
                      }
                    }}
                    className={classes.botao}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <div className={classes.boxNaoEncontrato}>
              {!carregandoCupom && "Cupom não encontrado!"}
            </div>
          )}
        </Grid>
      </Card>
    </Loader>
  );
};

export default ConvideGanhe;
