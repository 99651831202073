import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";

import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  MenuItem,
  Menu,
  Box,
  Grid
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { deslogarUsuario } from "global/redux/modulos/usuario/actions";
import {
  menuExibir,
  menuFechar,
  menuExibirTotalmente,
  menuExibirMetade,
  menuNaoExibirMetade
} from "global/redux/modulos/menu/actions";
import { store } from "global/redux";
import Data from "./componentes/data";
import AreaUsuario from "./componentes/areaUsuario";
import Notificacoes from "./componentes/notificacoes";

import { useStyles, Titulo } from "./style";
import SubMenu from "./componentes/subMenu";

const Cabecalho = ({ invisible, atualizaNotificacoesVisualizadas }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const rotas = useSelector((state) => state.rotas);
  const stateMenu = useSelector((state) => state.menu);
  const atualDTO = rotas?.rotaAtual?.caminho;

  const [auth] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [aberto, setAberto] = useState(255);
  const [totalmente] = useState(stateMenu.visivelTotalmente);

  const classes = useStyles({
    cabecalho: aberto,
    totalmente,
    hideHeader:
      ["Demo", "Home"].includes(atualDTO) || rotas?.rotaAtual?.esconderMenu
  });

  const stateUsina = useSelector((state) => state?.menu?.usina);

  const handleAberto = () => {
    if (stateMenu.visivelTotalmente) {
      setAberto(255);
      if (stateMenu.cabecalho) {
        setAberto(255);
      } else {
        setAberto(90);
      }
    } else {
      setAberto(90);
    }
  };

  useEffect(() => {
    handleAberto();
  }, [stateMenu.cabecalho, stateMenu.visivelTotalmente]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickSair = () => {
    store.dispatch(deslogarUsuario());
    window.location.reload(true);
    handleClose();
  };

  const onToggleMenu = () => {
    if (stateMenu.visivel) {
      store.dispatch(menuFechar());
    } else {
      store.dispatch(menuExibir());
    }
  };

  const usuario = useSelector((state) => state.usuario);
  const { menu } = usuario;
  const notificacoes = useSelector((state) => state.notificacoes);

  const [rotuloCabecalho, setRotuloCabecalho] = useState("");
  const [funcionalidade, setFuncionalidade] = useState({});

  const validarResultado = (resultado) => {
    if (stateUsina?.rotulo !== null && stateUsina?.rotulo !== "") {
      setRotuloCabecalho(stateUsina?.rotulo);
    } else if (resultado) {
      setRotuloCabecalho(resultado);
    } else {
      setRotuloCabecalho("Início");
    }
  };

  useEffect(() => {
    const rotulo = menu.filter(
      (item) =>
        item.functionality.funcionalidadeColecao.filter(
          (funcionalidade) => funcionalidade.caminho === atualDTO
        ).length > 0
    );

    setFuncionalidade(rotulo[0]?.functionality ?? {});
    const resultado = rotulo[0]?.functionality?.nome;
    validarResultado(resultado);
  }, [menu, atualDTO, stateUsina]);

  const expandir = () => {
    store.dispatch(menuExibirTotalmente());
    store.dispatch(menuNaoExibirMetade());
  };

  const expandirMetade = () => {
    store.dispatch(menuExibirMetade());
  };

  return matches ? (
    <AppBar
      position="fixed"
      className={`${invisible ? "invisible" : ""} ${classes.appBar}`}
    >
      <Box className={classes.areaSetaNavegacao}>
        <IconButton
          onClick={expandir}
          className={classes.setaNavegacao}
          size="large"
        >
          <DoubleArrowIcon />
        </IconButton>
        <IconButton
          onClick={expandirMetade}
          className={classes.setaNavegacao}
          size="large"
        >
          <ChevronRightIcon />
        </IconButton>
      </Box>
      <Box className={classes.bordaBranca}>
        <Toolbar className={`${classes.toolBar} position-relative`}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={4}>
              <Titulo>{rotuloCabecalho}</Titulo>
            </Grid>
            <Grid item xs={8} className="d-flex justify-content-end">
              <Data usuario={usuario?.usuario?.nome} />
              <Notificacoes
                notificacoes={notificacoes}
                atualizaNotificacoesVisualizadas={
                  atualizaNotificacoesVisualizadas
                }
              />
              <AreaUsuario />
            </Grid>
            <Grid item xs={12}>
              {atualDTO !== "Home" && !rotas?.rotaAtual?.esconderMenu ? (
                <SubMenu funcionalidade={funcionalidade} />
              ) : null}
            </Grid>
          </Grid>
        </Toolbar>
      </Box>
    </AppBar>
  ) : (
    <AppBar
      invisible={String(invisible)}
      position="sticky"
      className={`${invisible ? "invisible" : ""} ${classes.mobileAppBar}`}
    >
      <Toolbar className={classes.mobileToolbar}>
        <IconButton
          onClick={onToggleMenu}
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          size="large"
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          {rotuloCabecalho}
        </Typography>
        {auth && (
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              size="large"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>
                <Link className={classes.menuItem} to="/conta/alterar-senha">
                  Alterar Senha
                </Link>
              </MenuItem>
              <MenuItem onClick={onClickSair}>Sair</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
      {atualDTO !== "Home" && !rotas?.rotaAtual?.esconderMenu ? (
        <Box className={classes.sliderBox}>
          <SubMenu funcionalidade={funcionalidade} />
        </Box>
      ) : null}
    </AppBar>
  );
};

Cabecalho.propTypes = {
  invisible: PropTypes.bool,
  atualizaNotificacoesVisualizadas: PropTypes.func
};

Cabecalho.defaultProps = {
  invisible: false,
  atualizaNotificacoesVisualizadas: () => {}
};

export default Cabecalho;
